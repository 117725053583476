import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Benefit = ({ data }) => {
  const { name, logo, offer, longDescription, serviceType, callToAction } = data.contentfulBenefit;
  const Benefits = data.allContentfulBenefit.edges;
  return (
    <Layout>
      <SEO title={name} />
      <div className="">
      <Img
          className="object-cover w-full h-64"
          fluid={data.file.childImageSharp.fixed}
          alt="Header"
        />
      </div>
      <div className="container benefit px-4 mx-auto mt-12 mb-40">
        <h1 className="title">{name}</h1>

        <div className="flex flex-wrap mt-0">
          <div className="sm:w-2/3">


            <div className="bg-white px-6 pt-8 pb-8 mt-8">

            <div className="text-purple-800 text-sm font-semibold mb-4">
              {serviceType}</div>
            <div className="font-semibold mb-8">
            {offer}
              
            </div>
              <div className="pb-0">

                <div className="text-base"
                    dangerouslySetInnerHTML={{
                  __html: longDescription.childMarkdownRemark.html,
                }}
             />              
              </div>
 
  

              <div className="pb-16">
                <h2>How to Apply</h2>
                <div className="text-base"
                    dangerouslySetInnerHTML={{
                  __html: callToAction.childMarkdownRemark.html,
                }}
             />
              </div>


            </div>

          </div>
          <div className="hidden sm:block sm:w-1/4 p-4 ml-8 h-56 mt-8 bg-white grid place-items-center">

          <Img
              className="object-cover w-full"
              fluid={logo.fluid}
              alt="Header"
            />          

          </div>
        </div>
        <div className="hidden w-full :w-2/3 text-xs mt-12">
          {Benefits.map(({ node: post }) => (

            
            <Link className="text-purple-800 mr-2 whitespace-no-wrap" to={`/Benefits/${post.slug}`}>{post.name}</Link>
            
          ))}
        </div>
      </div>
    </Layout>
  );
};
export default Benefit;
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBenefit(slug: { eq: $slug }) {
      name
      slug
      offer
      serviceType
      longDescription {
        childMarkdownRemark {
          html
        }
      }
      callToAction {
        childMarkdownRemark {
          html
        }
      }
      logo {
        fluid(maxWidth: 2000, resizingBehavior: SCALE) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
    }
    allContentfulBenefit(sort: {fields: name, order: ASC}) {
      edges {
        node {
          id
          slug
          name
        }
      }
    }       
    file(relativePath: { eq: "banner-news.jpg" }) {
      childImageSharp {
        fixed(width: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }    
  }
`;